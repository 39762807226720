// Select the navbar element
const navbarSticky = document.getElementById('js-navbar');

// Function to handle the scroll event
function handleScroll() {
    // Get the total height of the document
    const documentHeight = document.documentElement.scrollHeight;

    // Get the height of the visible part of the page
    const windowHeight = document.documentElement.clientHeight;

    // Calculate the scrollable height of the document
    const scrollableHeight = documentHeight - windowHeight;

    // Calculate the 10% scroll threshold
    const threshold = scrollableHeight * 0.07;

    // Get the current scroll position
    const scrollPosition = window.scrollY || window.pageYOffset;

    // Check if the scroll position exceeds the threshold
    if (scrollPosition > threshold) {
        // Add the --not-top class
        navbarSticky.classList.add('--not-top');
    } else {
        // Remove the --not-top class
        navbarSticky.classList.remove('--not-top');
    }
}

// Attach the scroll event listener to the window
window.addEventListener('scroll', handleScroll);
