(function () {
    document.querySelectorAll('[data-role="custom-select"]').forEach(function(selectElement) {
        const optionsList = selectElement.nextElementSibling;

        selectElement.addEventListener('click', function() {
            console.log("MOO");
            optionsList.style.display = "block";
        });

        optionsList.addEventListener('click', function(event) {
            if (event.target.classList.contains('option-item')) {
                selectElement.value = event.target.innerHTML;
                optionsList.style.display = "none";
            }
        });

        document.addEventListener('click', function(event) {
            if (!selectElement.contains(event.target) && !optionsList.contains(event.target)) {
                optionsList.style.display = "none";
            }
        });
    });
})();