'use strict';

import DataTransport from '@/helpers/data-transport';

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const transport = new DataTransport();
    const orderLinks = document.querySelectorAll('[data-role="order-link"]');

    orderLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            const orderLink = e.currentTarget;
            const currentRow = orderLink.closest('tr'); // Find the closest <tr> to the clicked link
            let nextElement = currentRow.nextElementSibling;

            // Find the next <tr> element with the class orderData
            while (nextElement) {
                if (nextElement.getAttribute('data-role') === 'order-data') {
                    // Toggle data-order between '1' and '0'
                    let currentOrder = nextElement.getAttribute('data-display');
                    if (currentOrder === '1') {
                        nextElement.classList.add("d-none");
                        nextElement.setAttribute('data-display', '0');
                    } else {
                        nextElement.classList.remove("d-none");
                        nextElement.setAttribute('data-display', '1');
                    }
                    break;
                }
                nextElement = nextElement.nextElementSibling;
            }
        });
    });
})();