'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    document.getElementById('resetPasswordFrm').addEventListener('submit', async function (e) {
        e.preventDefault();

        // Get the submit button
        const submitButton = this.querySelector('button[type="submit"]');
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
            // For debugging or customization purposes, log the form data as key-value pairs
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }
        */

        submitButton.innerHTML = "Please Wait";
        submitButton.disabled = true;

        await transport.PostFormData('/Login/?exec=resetPassword', formData)
            .then(async data => {
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    } else {
                        if (data.data.redirect) {
                            window.location = data.data.redirect;
                        } else {
                            // success
                            alertMessage.ShowSuccess(data.message);
                            submitButton.innerHTML = submitButtonTxt;
                            submitButton.disabled = false;
                        }
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();