(function (){
    const carouselElement = document.getElementById('jsGamesSlider');
    const carousel = new bootstrap.Carousel(carouselElement, { interval: false });
    const carouselItems = document.querySelectorAll('.jsGamesSlider__item');
    const thumbnails = document.querySelectorAll('.jsGameSlider__thumbnail');
    /* Ensure the value of const interval="" matches the data-bs-interval="" value in home.tpl */
    const interval = 8000; // Carousel interval (milliseconds)

    let progressBarInterval;
    let activeIndex = 0;

// Function to start the progress bar
    const startProgressBar = (index) => {
        clearInterval(progressBarInterval);  // Clear any existing progress bar interval
        resetProgressBars();  // Reset all progress bars before starting a new one

        setTimeout(() => {
            const progressBarContainer = document.getElementById(`progress-bar-container-${index}`);
            const progressBar = document.getElementById(`progress-bar-${index}`);
            let progress = 0;
            progressBarContainer.classList.add('active');

            progressBarInterval = setInterval(() => {
                progress += 100 / (interval / 100);
                progressBar.style.width = `${progress}%`;

                if (progress >= 100) {
                    clearInterval(progressBarInterval);
                    progressBar.style.width = '100%';

                    // Move to the next slide immediately
                    if (activeIndex < carouselItems.length - 1) {
                        carousel.next();
                    } else {
                        carousel.to(0); // Loop back to the first item
                    }
                }
            }, 50);
        }, 50); // Delay of 50ms
    };

// Function to reset all progress bars
    const resetProgressBars = () => {
        carouselItems.forEach((_, index) => {
            const progressBarContainer = document.getElementById(`progress-bar-container-${index}`);
            progressBarContainer.classList.remove('active');
            const progressBar = document.getElementById(`progress-bar-${index}`);
            if (progressBar) {
                progressBar.style.width = '0';
            }
        });
    };

// Function to update the active thumbnail and start the progress bar
    const updateActiveThumbnail = (newIndex) => {
        thumbnails.forEach((thumbnail, index) => {
            thumbnail.classList.toggle('active', index === newIndex);
            if (index === newIndex) {
                activeIndex = newIndex;
                startProgressBar(index);  // Start progress bar for the new active slide
            }
        });
    };

// Add event listeners to the thumbnails for clicking
    thumbnails.forEach((thumbnail, index) => {
        thumbnail.addEventListener('click', () => {
            carousel.to(index);
            clearInterval(progressBarInterval);  // Clear the progress bar interval when a thumbnail is clicked
        });
    });

// Add event listener to carousel for slide change
    carouselElement.addEventListener('slide.bs.carousel', (event) => {
        clearInterval(progressBarInterval);  // Clear the progress bar interval when the carousel slide is triggered
        const newIndex = event.to;
        updateActiveThumbnail(newIndex);
    });

// Initialize
    updateActiveThumbnail(0);
})();