'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const productUpdateFrm = document.querySelector('[data-role="product-update-form"]');

    productUpdateFrm.addEventListener('submit', async function(e) {
        e.preventDefault();

        // Get the submit button
        const submitButton = this.querySelector('button[type="submit"]');
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        submitButton.innerHTML = "Please Wait";
        submitButton.disabled = true;

        await transport.PostFormData(`/admincp/UserProduct/?userId=${packData.userId}&exec=add`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    } else {
                        // success
                        window.location = `/admincp/UserProduct/?clusterId=${data.data.clusterId}&userId=${packData.userId}&msgCode=1008`;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();