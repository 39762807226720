'use strict';

import AlertMessage from "@/helpers/alert-message";

(function() {
    const alertMessage = new AlertMessage();
    const url = new URL(window.location.href);
    const cliParserRemoveForm = document.querySelector('[data-role="cli-parser-remove-form"]');
    const cliParserRemoveText = document.querySelector('[data-role="cli-parser-remove-text"]');
    const templateId = url.searchParams.get('templateId') ?? "";
    const gameId = url.searchParams.get('gameId') ?? "";

    cliParserRemoveForm.addEventListener("submit", (e) => {
        e.preventDefault();

        const value = cliParserRemoveText.value.trim().toLowerCase();

        if (value !== 'remove this config') {
            alertMessage.ShowError("You must type 'remove this config'");
        } else {
            window.location.href = `/admincp/CliParserUpdate/?exec=removeTemplate&templateId=${templateId}&gameId=${gameId}`;
        }
    })
})();