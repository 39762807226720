'use strict';

import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const serverLog = document.querySelector('[data-role="server-log"]');
    const serverLogTemplate = document.querySelector('[data-role="server-log-template"]');

    document.addEventListener('statusUpdateEvent', (status) => {
        if (status.detail) {
            if (!status.detail.error) {
                // Data needs to be defined because a lot of copy and pasting moving from 1 file to another, less likely chance of failure (refactor later)
                let data = status.detail;

                if (data.LogMessages) {
                    //console.log(data.LogMessages);
                    if (data.LogMessages.length > 0) {
                        serverLog.innerHTML = '';

                        data.LogMessages.forEach((e) => {
                            const clonedTemplate = serverLogTemplate.cloneNode(true);

                            // Find the cloned datetime element and replace its innerHTML
                            const clonedDatetimeElement = clonedTemplate.querySelector('.js_datetime');
                            const date = new Date(e.DateTime);
                            const options = {
                                weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',
                                hour: 'numeric', minute: 'numeric', second: 'numeric',
                                timeZoneName: 'short'
                            };
                            const formatter = new Intl.DateTimeFormat(undefined, options);

                            // Format and print
                            const readableDate = formatter.format(date);
                            // Set the date
                            clonedDatetimeElement.innerHTML = readableDate;

                            // Find the cloned message element and replace its innerHTML
                            const clonedMessageElement = clonedTemplate.querySelector('.js_message');
                            clonedMessageElement.innerHTML = e.Message;

                            serverLog.innerHTML += clonedTemplate.innerHTML;
                        });
                    }
                }
            }
        }
    });
})();