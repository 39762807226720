'use strict';

import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    const updateNodeForm = document.querySelector('[data-role="update-node-form"]');
    const url = new URL(window.location);
    const nodeId = parseInt(url.searchParams.get('nodeId'));

    updateNodeForm.addEventListener('submit', async function(e) {
       e.preventDefault();

        // Get the submit button
        const submitButton = this.querySelector('button[type="submit"]');
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        submitButton.innerHTML = "Please Wait";
        submitButton.disabled = true;

        await transport.PostFormData(`/admincp/UpdateNode/?nodeId=${nodeId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    } else {
                        // success
                        alertMessage.ShowSuccess(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();