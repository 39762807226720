'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const configureFormBtn = document.getElementById('configureFormBtn');
    const configureFormBtnText = configureFormBtn.innerHTML;

    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId');

    document.querySelector('[data-role="checkout-configure-form"]').addEventListener('submit', async function(e) {
        e.preventDefault();
        alertMessage.HideAlert();


        configureFormBtn.disabled = true;
        configureFormBtn.innerHTML = 'Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);



        await transport.PostFormData(`/Checkout/?exec=beta&gameId=${gameId}`, formData)
            .then(async data => {
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);

                        configureFormBtn.disabled = false;
                        configureFormBtn.innerHTML = configureFormBtnText;
                    } else {
                        for (const key of formData.keys()) {
                            const input = this.querySelector(`[name="${key}"]`);
                            if (input) {
                                input.disabled = true;
                            }
                        }

                        alertMessage.ShowSuccess(data.message);
                        configureFormBtn.innerHTML = "Sent!";
                    }
                } else {
                    // Raw Text;
                    console.log(data);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();