module.exports = class PingQuery extends EventTarget {
    #game = "";
    #ip = "";
    #port = ""; // String as we send multiple ports with port,port,port
    #interval = 0;
    #set = false;
    #running = false;

    constructor() {
        super();
    }
    #CheckParams() {
        if (this.#game.length <= 0)
            return false;
        if (this.#ip.length <= 0)
            return false;
        if (this.#port <= 0)
            return false;
        if (this.#interval <= 0)
            return false;

        return true;
    }
    SetParams(game, ip, port, interval) {
        // No need to ever go faster than 2 second intervals.
        if (interval < 2000)
            interval = 2000;
        if (game.length <= 0)
            throw new Error("Game is not currently supported.");

        this.#game = game;
        this.#ip = ip;
        this.#port = port;
        this.#interval = interval;
    }

    /**
     * StartQuery can safely be run multiple times as it will only start once.
     * @returns {Promise<void>}
     * @constructor
     */
    async StartQuery() {
        // If our interval is already running, just die.
        if (this.#running)
            return;

        // Check our variables and just die if nothing is set.
        if (!this.#CheckParams())
            return;

        // Set running to true
        this.#running = true;

        while (true) {
            if (this.#running) {
                await this.#Query(this.#ip, this.#port);
                await new Promise(resolve => setTimeout(resolve, this.#interval));
            } else {
                break;
            }
        }
    }
    StopQuery() {
        this.#running = false;
    }
    async #Query() {
        try {
            const response = await fetch(window.location.origin + ':2053/query?type=' + this.#game + '&host=' + this.#ip + '&port=' + this.#port, {
                method: 'GET', // Specify the request method
            });
            if (!response.ok) {
                console.error("Failed connecting to PingQuery");
            }
            const query = await response.json();
            const event = new CustomEvent('query', { detail: query });
            this.dispatchEvent(event);
        } catch (error) {
            const event = new CustomEvent('query', { detail: { error: "Unable to connect to PingQuery" } });
            this.dispatchEvent(event);
        }
    }
}