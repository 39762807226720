'use strict';

(function() {
    const searchField = document.querySelector('[data-role="search-field"]');
    const templateFields = document.querySelectorAll('[data-role="template-fields"]');
    const fieldFilenames = document.querySelectorAll('[data-role="field-filename"]');
    const fieldHeaders = document.querySelectorAll('[data-role="field-header"]');
    searchField.addEventListener('keyup', (e) => {
        const search = searchField.value.trim().toLowerCase();
        if (search.length > 2) {
            // Hide the filenames and headers
            fieldFilenames.forEach((e) => {
                e.classList.add("d-none");
            });
            fieldHeaders.forEach((e) => {
                e.classList.add("d-none");
            });
            templateFields.forEach((e) => {
                const fieldShortDesc = e.querySelector('[data-role="field-short-desc"]').getAttribute("data-contents").toLowerCase();
                const fieldLongDesc = e.querySelector('[data-role="field-long-desc"]').getAttribute("data-contents").toLowerCase();
                const input = e.querySelector('input');
                // Check if search term is in the short description or long description
                if (fieldShortDesc.includes(search) || fieldLongDesc.includes(search)) {
                    e.classList.remove("d-none");
                } else {
                    e.classList.add("d-none");
                }
                // If input exists, you can do something with it
                if (input) {
                    // Example: You can check the value of the input or manipulate it as needed
                    const inputValue = input.value.trim().toLowerCase();
                    if (inputValue.includes(search)) {
                        // Additional logic if input value also matches search
                        e.classList.remove("d-none");
                    }
                }
            });
        } else {
            fieldFilenames.forEach((e) => {
                e.classList.remove("d-none");
            });
            fieldHeaders.forEach((e) => {
                e.classList.remove("d-none");
            });
            templateFields.forEach((e) => {
                e.classList.remove("d-none");
            });
        }
    });
})();