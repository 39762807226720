'use strict';

import LoadingPage from "@/helpers/loading-page";
import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const loadingPage = new LoadingPage();
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const form = document.querySelector('[data-role="article-write-form"]');
    const game= document.querySelector('[data-role="game"]');
    const articleSelection = document.querySelector('[data-role="article-selection"]');
    const articleTitle = document.querySelector('[data-role="article-title"]');
    const articleSystemPrompt = document.querySelector('[data-role="article-system-prompt"]');
    const articlePrompt = document.querySelector('[data-role="article-prompt"]');


    let clickedButton = null;
    const buttons = document.querySelectorAll('button[type="submit"]');
    buttons.forEach(button => {
        button.addEventListener('click', function() {
            clickedButton = this;
        });
    });

    function DisableButtons() {
        buttons.forEach((e) => {
            e.disabled = true;
        });
    }

    function EnableButtons() {
        buttons.forEach((e) => {
            e.disabled = false;
        })
    }

    form.addEventListener('submit', async function(e) {
        e.preventDefault();

        if (clickedButton) {
            // Get the data-role attribute of the clicked button
            const dataRole = clickedButton.getAttribute('data-role');
            //console.log('Clicked button data-role:', dataRole);

            if (dataRole == 'write') {
                WriteArticle(clickedButton, this, true);
            } else if (dataRole == "writebypass") {
                WriteArticle(clickedButton, this, false);
            } else {
                SaveTemplate(clickedButton, this);
            }
        } else {
            return;
        }

        clickedButton = null;
    });

    async function WriteArticle(submitButton, form, useAi) {
        useAi = !!useAi; // Enforce boolean.

        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(form);

        /*
        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        return;
         */

        submitButton.innerHTML = "Please Wait";
        DisableButtons();

        await transport.PostFormData(`/admincp/Knowledgebase/?exec=writeArticle&useAi=${useAi ? 1 : 0}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        EnableButtons();
                        submitButton.innerHTML = submitButtonTxt;
                    } else {
                        window.location = '/admincp/Knowledgebase/?exec=viewPage&id=' + data.data.pageId;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }

    async function SaveTemplate(submitButton, form) {
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(form);

        /*
        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        return;
         */

        submitButton.innerHTML = "Please Wait";
        DisableButtons();

        await transport.PostFormData('/admincp/Knowledgebase/?exec=updatePredefinedTemplate', formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        EnableButtons();
                        submitButton.innerHTML = submitButtonTxt;
                    } else {
                        RefreshPageSuccess();
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }

    function RefreshPageSuccess() {
        // Create a URLSearchParams object from the current URL's query string
        let params = new URLSearchParams(window.location.search);

        // Add the new parameter or update it if it already exists
        params.set('msgCode', '1001');

        // Construct the new URL
        let newUrl = window.location.pathname + '?' + params.toString();

        // Reload the page with the new URL
        window.location.href = newUrl;
    }
})();