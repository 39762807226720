'use strict';

import DataTransport from '@/helpers/data-transport';

const profileImage = document.querySelector('[data-role="profile-image"]');
profileImage.addEventListener("change", (e) => {
   previewImage(e);
});

function previewImage(event) {
    const reader = new FileReader();
    const imageField = document.getElementById('image_preview');

    reader.onload = function() {
        if (reader.readyState == 2) {
            imageField.src = reader.result;
        }
    }

    reader.readAsDataURL(event.target.files[0]);
}