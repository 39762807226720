'use strict';

import DataTransport from '@/helpers/data-transport';

export default class Sanitizer {
    #inputArray = {};
    constructor() {
        const transport = new DataTransport();

        transport.GetResponse('/api/InputTypes/', {})
            .then(data => {
                this.#inputArray = data;

                this.#BindEvents();
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }

    #BindEvents() {
        document.querySelectorAll('[data-inputtypeid]').forEach((e) => {
            e.addEventListener('change', (e) => {
                const inputTypeId = e.target.getAttribute('data-inputtypeid');
                e.target.value = this.ValidateInput(inputTypeId, e.target.value);
            });
            e.addEventListener('input', (e) => {
                const inputTypeId = e.target.getAttribute('data-inputtypeid');
                e.target.value = this.ValidateInput(inputTypeId, e.target.value);
            });
        });
    }

    ValidateInput(inputTypeId, string) {
        for (const i of this.#inputArray) {
            if (i.inputTypeId == parseInt(inputTypeId)) {
                const regex = new RegExp(i.regex, 'g');

                // Regex has found OTHER strings, so return false.
                if (regex.test(string)) {
                    return string.replace(regex, '');
                }
            }
        }

        return string;
    }
}