// This file is pretty crap. Shouldn't be a helper and can be moved into the corresponding section.
'use strict';
module.exports = class CliParserTemplateVariables {
    get container() {
        return document.getElementById('rows-container');
    }
    get cliInputContainerBlank() {
        return document.getElementsByClassName('cliInputContainerBlank')[0];
    }

    constructor() {
        this._draggedElement = null;

        document.getElementById('templateType').addEventListener("change", (event) => {
            this.TemplateTypeChange(event.target);
        });
        this.TemplateTypeChange(document.getElementById('templateType'));

        document.querySelectorAll('.cliInputContainer').forEach(row => {
            this.AttachDeleteFunctionality(row);
            this.AttachPositionChangeListener(row); // Add position change listener
        });

        document.querySelectorAll('.cliInputContainer').forEach((element) => {
            //this.AddDragging(element);
        });
    }

    NewCliRow(position) {
        try {
            const newRow = this.cliInputContainerBlank.cloneNode(true);
            Array.from(newRow.querySelectorAll('input')).forEach(input => {
                if (input.type === 'checkbox') {
                    input.checked = false;
                } else {
                    input.value = '';
                }
            });
            newRow.classList.remove('d-none');
            newRow.classList.remove('cliInputContainerBlank');
            newRow.classList.add('cliInputContainer');
            //this.AddDragging(newRow);
            this.AttachDeleteFunctionality(newRow);
            this.AttachPositionChangeListener(newRow); // Add position change listener
            this.AttachInsertNewRowAtPosition(newRow.querySelector('[data-role="new-cli-position"]'));
            this.container.appendChild(newRow);
            this.UpdatePositionInputs(); // Update positions after adding a new row


            if (Number.isInteger(position)) {
                this.MoveRowToPosition(newRow, position);
            }
        } catch (e) {
            console.error(e);
        }
    }

    AttachInsertNewRowAtPosition(e) {
        e.addEventListener('click', ( element) => {
            // Start from the clicked element (event.target)
            let clickedElement = element.target;

            // Move upwards to the closest common parent (this could be a section, div, etc.)
            // In your case, I assume the next `.row` containing the `.cliPosition` input follows
            let parentRow = clickedElement.closest('.row'); // Adjust if needed for higher-level elements
            const cliPositionInput = parentRow.querySelector('.cliPosition');
            if (cliPositionInput) {
                this.NewCliRow(parseInt(cliPositionInput.value) - 1);
            }
        });
    }

    AttachDeleteFunctionality(row) {
        const deleteButton = row.querySelector('button.js_delete');
        deleteButton.addEventListener('click', () => {
            this.DeleteCliRow(deleteButton);
        });
    }

    DeleteCliRow(button) {
        const row = button.closest('.cliInputContainer');
        if (row) {
            this.container.removeChild(row);
            this.UpdatePositionInputs(); // Update positions after deleting a row
        }
    }

    TemplateTypeChange(element) {
        if (element.value === 'cli') {
            document.getElementById("templateTypeCLI").style.display = "inline";
            document.getElementById("templateTypeFile").style.display = "none";
        } else {
            document.getElementById("templateTypeCLI").style.display = "none";
            document.getElementById("templateTypeFile").style.display = "inline";
        }
    }

    GetClosestParent(child, className) {
        let current = child;
        while (current !== null && !current.classList.contains(className)) {
            current = current.parentElement;
        }
        return current;
    }

    AttachPositionChangeListener(row) {
        const positionInput = row.querySelector('.cliPosition');
        positionInput.addEventListener('change', (e) => {
            const newPosition = parseInt(e.target.value, 10) - 1;
            if (!isNaN(newPosition) && newPosition >= 0) {
                this.MoveRowToPosition(row, newPosition);
            }
        });
    }

    MoveRowToPosition(row, newPosition) {
        const allElements = Array.from(document.querySelectorAll('.cliInputContainer'));
        const currentIndex = allElements.indexOf(row);

        if (currentIndex > -1 && newPosition >= 0 && newPosition < allElements.length) {
            if (currentIndex < newPosition) {
                allElements[newPosition].after(row);
            } else {
                allElements[newPosition].before(row);
            }
            this.UpdatePositionInputs(); // Update positions after manually changing the position
        }
    }

    UpdatePositionInputs() {
        document.querySelectorAll('.cliInputContainer').forEach((element, index) => {
            const positionInput = element.querySelector('.cliPosition');
            const variable = element.querySelector('input[name="variableName[]"]');
            const position = index + 1;
            //console.log(variable.value + " position set to " + position);
            positionInput.value = position;
        });
    }

    ClearRows() {
        document.querySelectorAll('.cliInputContainer').forEach((element, index) => {
            if (index !== 0) {
                element.parentNode.removeChild(element);
            }
        });
    }
}