'use strict';

import DataTransport from '@/helpers/data-transport';
import LoadingPage from '@/helpers/loading-page';

export default class ServerStatus {
    transport = new DataTransport();
    loadingPage = new LoadingPage();
    gameServerId = 0;
    showLog = false;
    currentLoadingMsg = "Loading Data";

    constructor(gameServerId, showLog) {
        this.gameServerId = gameServerId;
        this.showLog = showLog;

        document.querySelector('[data-role="server-restart"]').addEventListener('click', () => { this.restartServer() });
        document.querySelector('[data-role="server-shutdown"]').addEventListener('click', () => { this.shutdownServer() });


        this.GetStatus();
    }

    updateServerStatus(newStatus) {
        // Create a new CustomEvent with the updated serverStatus
        const statusUpdate = new CustomEvent('statusUpdateEvent', {
            detail: newStatus
        });

        // Dispatch the event
        document.dispatchEvent(statusUpdate);
    }

    async GetStatus() {
        await this.transport.GetResponse(`/MyServers?exec=GetStatus&gameServerId=${this.gameServerId}&showLog=${this.showLog}`, { })
            .then(data => {
                this.updateServerStatus(data);

                console.log(data);

                if (!data.error) {
                    // We need to decide if we are showing our server controls or not
                    if (data.MyServerStatus.QueueBoot || data.MyServerStatus.QueueShutdown || data.MyServerStatus.RunningTask || data.MyServerStatus.QueueInstallation || !data.MyServerStatus.Installed) {
                        console.log(data.MyServerStatus.Status);
                        this.hideServerControls(data.MyServerStatus.Status);
                    } else {
                        this.showServerControls();
                    }
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });

        await new Promise(r => setTimeout(r, 5000));

        await this.GetStatus();
    }

    showServerControls() {
        document.querySelector('[data-role="server-restart"]').classList.remove("d-none");
        document.querySelector('[data-role="server-shutdown"]').classList.remove("d-none");
        this.loadingPage.HideLoading();
    }

    hideServerControls(msg="") {
        document.querySelector('[data-role="server-restart"]').classList.add("d-none");
        document.querySelector('[data-role="server-shutdown"]').classList.add("d-none");
        this.currentLoadingMsg = msg;
        this.loadingPage.ShowLoading(this.currentLoadingMsg);
    }

    restartServer() {
        this.hideServerControls("Rebooting, please wait");
        this.transport.GetRawResponse(`/MyServers?exec=RestartServer&gameServerId=${this.gameServerId}`, {})
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            })
            .finally(() => {

            });
    }

    shutdownServer() {
        this.hideServerControls("Shutting down, please wait");
        this.transport.GetRawResponse(`/MyServers?exec=ShutdownServer&gameServerId=${this.gameServerId}`, {})
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            })
            .finally(() => {

            });
    }
}