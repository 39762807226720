'use strict';

const parserTextArea = document.querySelector('[data-role="parser-input"]');
const highlightedText = document.querySelector('[data-role="parser-text"]');
const setDefaultValuesBtn = document.querySelectorAll('[data-role="set-default-values"]');
const enumCheck = document.querySelectorAll('[data-role="enum-check"]');

setDefaultValuesBtn.forEach((e) => {
   e.addEventListener("click", (e) => {
       const id = e.target.getAttribute("data-id");

       // Default keys
       const defaultReplace = document.querySelector('[data-role="default-value-replace"]');
       const defaultVariable = document.querySelector('[data-role="default-value-variable"]');
       const defaultDef = document.querySelector('[data-role="default-value-def"]');

       const replace = document.querySelector(`[data-id="replace-${id}-${defaultReplace.value}"]`);
       const variable = document.querySelector(`[data-id="variable-${id}-${defaultVariable.value}"]`);
       const def =  document.querySelector(`[data-id="default-${id}-${defaultDef.value}"]`);

       // We use data-clicked to allow us to "undo" the click.
       if (e.target.getAttribute("data-clicked") == "1") {
           uncheckParserCheckbox(replace);
           uncheckParserCheckbox(variable);
           uncheckParserCheckbox(def);
           e.target.setAttribute("data-clicked", "0");
       } else {
           parserCheckbox(replace);
           parserCheckbox(variable);
           parserCheckbox(def);
           replace.checked = true
           variable.checked = true;
           def.checked = true;
           e.target.setAttribute("data-clicked", "1");
       }

   })
});

enumCheck.forEach((e) => {
    e.addEventListener("click", (checkbox) => {
        const target = checkbox.target.getAttribute("data-target");
        const contents = checkbox.target.getAttribute("data-contents");
        const input= document.querySelector('input[name="'+target+'"]');

        input.value = contents;
        checkbox.target.checked = false;
    });
});

function escapeHtml(text) {
    if (typeof text !== 'string') {
        console.error('Invalid text type:', text);
        return '';
    }

    // Temporarily replace the highlight tags with placeholders
    text = text.replace(/<span class="highlight">/g, '___HIGHLIGHT_START___');
    text = text.replace(/<\/span>/g, '___HIGHLIGHT_END___');

    // Escape the rest of the text
    text = text.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");

    // Restore the highlight tags
    text = text.replace(/___HIGHLIGHT_START___/g, '<span class="highlight">');
    text = text.replace(/___HIGHLIGHT_END___/g, '</span>');

    return text;
}

function highlightMatches() {
    const text = parserTextArea.value;
    const regexPatterns = getParserRegexValues();

    let highlightedText = text; // Escape the whole text initially

    regexPatterns.forEach(pattern => {
        if (pattern.length > 0 && isValidRegex(pattern)) {
            const regex = new RegExp(pattern, 'gmi');
            highlightedText = highlightedText.replace(regex, (match, ...groups) => {
                let highlightedMatch = match;
                groups.forEach((group, index) => {
                    if (group) {
                        highlightedMatch = highlightedMatch.replace(group, `<span class="highlight">${group}</span>`);
                    }
                });
                return `<span class="highlight">${highlightedMatch}</span>`;
            });
        }
    });

    // Insert the highlighted text into the <pre> element as HTML
    document.querySelector('[data-role="parser-text"]').innerHTML = escapeHtml(highlightedText) + '\n';
}
highlightMatches();
parserTextArea.addEventListener('input', highlightMatches);
// Synchronize scrolling
parserTextArea.addEventListener('scroll', function() {
    highlightedText.scrollTop = parserTextArea.scrollTop;
    highlightedText.scrollLeft = parserTextArea.scrollLeft;
});

document.querySelectorAll('[data-role="nav-link"]').forEach(tab => {
    tab.addEventListener('shown.bs.tab', function (event) {
        if (event.target.id === 'parser-tab') {
            // Show the pre element and recalculate highlights
            highlightedText.classList.remove('d-none');
            highlightMatches();
        } else {
            // Hide the pre element when the other tab is active
            highlightedText.classList.add('d-none');
        }
    });
});
document.querySelectorAll('[data-role="regex-change"]').forEach(e => {
    e.addEventListener('change', function(event) {
        highlightMatches();
    });
});
document.querySelectorAll('[data-role="parser-checkbox"]').forEach(e => {
    e.addEventListener('click', function(event) {
        parserCheckbox(event.target);
    });
});

function parserCheckbox(checkbox) {
    const checkboxes = document.getElementsByName(checkbox.name);
    checkboxes.forEach((item) => {
        if (item !== checkbox) item.checked = false;
    });
}
function uncheckParserCheckbox(checkbox) {
    const checkboxes = document.getElementsByName(checkbox.name);
    checkboxes.forEach((item) => {
        item.checked = false;
    });
}

function isValidRegex(pattern) {
    try {
        new RegExp(pattern);
        return true;
    } catch (e) {
        return false;
    }
}

function getParserRegexValues() {
    // Select all input elements with name "parser_regex[]"
    const regexInputs = document.querySelectorAll('input[name="parser_regex[]"]');
    const regexEnabled = document.querySelectorAll('select[name="parser_regex_enabled[]"]');

    // Ensure both NodeLists have the same length
    if (regexInputs.length !== regexEnabled.length) {
        console.error('Mismatched number of regex inputs and regex enabled inputs.');
        return [];
    }

    // Map the NodeList to an array of values if the corresponding regexEnabled input equals 1
    const regexValues = Array.from(regexInputs).map((input, index) => {
        return regexEnabled[index].value === '1' ? input.value : null;
    }).filter(value => value !== null);

    return regexValues;
}

