'use strict';

import rangeSlider from 'rangeslider-pure';

(function (){
// Select all range sliders on the page
    const sliders = document.querySelectorAll('[type="range"]');

// Iterate over each slider
    sliders.forEach((slider) => {
        let tooltip;

        // Initialize the rangeSlider for the current slider element
        rangeSlider.create(slider, {
            onSlide: (val) => {
                if (slider.id == 'daysSlider') {
                    tooltip.textContent = document.getElementById('daysInputContent').innerText;
                }

                if (slider.id == 'playerSlider') {
                    tooltip.textContent = val;
                }
            }
        });

        // Get the handle element of the current range slider
        const handleEl = slider.rangeSlider.handle;

        // Create a new tooltip div
        tooltip = document.createElement('div');
        tooltip.classList.add('tooltip-players');

        // Append the tooltip to the handle element
        handleEl.appendChild(tooltip);

        if (slider.id == 'daysSlider') {
            tooltip.textContent = document.getElementById('daysInputContent').innerText;
        } else {
            // Set the initial tooltip text to the slider's current value
            tooltip.textContent = slider.rangeSlider.value;
        }

    });

})();
