'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const checkoutEmailFrm = document.querySelector('[data-role="checkout-email-form"]');
    const checkoutEmailFrmText = checkoutEmailFrm.innerHTML;

    checkoutEmailFrm.addEventListener('submit', async function (e) {
        e.preventDefault();


        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        */

        alertMessage.HideAlert();

        await transport.PostFormData(`/Checkout?exec=register&gameId=${packData.gameId}`, formData)
            .then(async data => {
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                    } else {
                        window.location.href = data.data.url;
                    }
                } else {
                    // Raw Text;
                    console.log(data);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();