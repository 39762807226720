'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const selectPaymentBtn = document.getElementById('selectPaymentBtn');
    const selectPaymentBtnText = selectPaymentBtn.innerHTML;

    selectPaymentBtn.addEventListener('click', async function(e) {
        alertMessage.HideAlert();

        let selectedPaymentProvider;
        // if checkoutCompletePaypal, then we've already been to paypal and been sent back. As such, we KNOW that we are using paypal, so nothing to do here
        if (!packData.checkoutCompletePaypal) {
            selectedPaymentProvider = document.querySelector('input[name="gateway"]:checked');
            if (!selectedPaymentProvider) {
                ShowWarning("Please select a payment method");
                return;
            }
        }

        selectPaymentBtn.disabled = true;
        selectPaymentBtn.innerHTML = 'Please Wait <div class="spinner-border spinner-border-sm" role="status"></div>';

        // Configure the URL based on our existing URL.
        const url = new URL(window.location);
        url.searchParams.set('exec', 'selectPayment');
        // Force paypal if we know its a paypal payment for completion
        if (packData.checkoutCompletePaypal) {
            url.searchParams.set('gateway', 'paypalsaved');
        } else {
            url.searchParams.set('gateway', selectedPaymentProvider.id);
        }
        const queryString = url.search;

        await transport.PostFormData('/Checkout' + queryString)
            .then(async data => {
                if (typeof data === 'object') {
                    console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);

                        selectPaymentBtn.disabled = false;
                        selectPaymentBtn.innerHTML = selectPaymentBtnText;
                    } else {
                        window.location.href = data.data.url;
                    }
                } else {
                    // Raw Text;
                    console.log(data);
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();