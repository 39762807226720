'use strict';

export default class LoadingPage {
    constructor() {
        this.loadingScreen = document.querySelector('[data-role="loading-page"]');
        this.loadingText = document.querySelector('[data-role="loading-page-text"]');
        this.defaultText = this.loadingText.innerHTML;
    }

    ShowLoading(msg="") {
        this.loadingScreen.classList.add("loading-page");
        this.loadingText.innerHTML = msg.length > 0 ? msg : this.defaultText;
    }
    HideLoading() {
        this.loadingScreen.classList.remove("loading-page");
    }

}