'use strict';

import CliParserTemplateVariables from '@/helpers/cli-parser-template-variables';
import LoadingPage from "@/helpers/loading-page";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const cliParser = new CliParserTemplateVariables();
    const loadingPage = new LoadingPage();

    if (packData.template) {
        if (packData.templateFields == 1) {
            cliParser.NewCliRow();
        } else {
            console.log("No need to add a new row.");
        }

        document.querySelector('[data-role="add-cli-button"]').addEventListener('click', function (e) {
            // Check if the event is from an input element
            cliParser.NewCliRow();
        });

        document.querySelector('[data-role="clear-fields-button"]').addEventListener('click', function(e) {
            cliParser.ClearRows();
        });

        document.querySelector('[data-role="get-descriptions-ai"]').addEventListener('click', function(e) {
            loadingPage.ShowLoading("Please wait");

            // Get the current URL
            const url = new URL(window.location.href);

            // Append the 'useAI=1' query parameter to the URL
            url.searchParams.set('useAI', '1');
            url.searchParams.set('tab', 'template');

            // Reload the page with the updated URL
            window.location.href = url.toString();
        });

        document.querySelectorAll('[data-role="new-cli-position"]').forEach( (e) => {
            cliParser.AttachInsertNewRowAtPosition(e);
        });

        cliParser.UpdatePositionInputs();
    }
})();
