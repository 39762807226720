'use strict';
module.exports = class DataTransport {
    constructor() {

    }

    async GetResponse(url, data) {
        try {
            const response = await fetch(url, {
                method: 'POST', // Specify the request method
                headers: {
                    'Content-Type': 'application/json' // Specify the content type of the payload
                },
                body: JSON.stringify(data) // Convert the data object to a JSON string
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return await response.json(); // Parse the JSON response body and return it
        } catch (error) {
            console.error('Error:', error);
            throw error; // Rethrow the error for further handling if necessary
        }
    }

    async GetRawResponse(url, data) {
        try {
            const response = await fetch(url, {
                method: 'POST', // Specify the request method
                headers: {
                    'Content-Type': 'application/json' // Specify the content type of the payload
                },
                body: JSON.stringify(data) // Convert the data object to a JSON string
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Attempt to parse the response as JSON
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
                return await response.json();  // Parse and return as JSON
            } else {
                return await response.text();  // Parse and return as text
            }
        } catch (error) {
            console.error('Error:', error);
            throw error; // Rethrow the error for further handling if necessary
        }
    }

    async PostFormData(url, formData) {
        try {
            // Send the data as a POST request
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok)
                throw new Error('Network response was not ok');

            // Check if the request was successful
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
                return await response.json();  // Parse and return as JSON
            } else {
                return await response.text();  // Parse and return as text
            }
        } catch (error) {
            console.error('Network error:', error);
            throw error;
        }
    }
}