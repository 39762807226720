'use strict';

import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const ftpUsername = document.querySelector('[data-role="ftp-username"]');
    const ftpPassword = document.querySelector('[data-role="ftp-password"]');

    // on first load also blank out ftp details
    ftpUsername.innerHTML = "*********";
    ftpPassword.innerHTML = "*********";

    // Use variables to store ftp data as soon as we get them
    let ftpUser = "";
    let ftpPass = "";

    // Function to toggle visibility of input field
    function toggleInputVisibility(event) {
        const inputField = event.target;
        const iconEyeOpen = inputField.nextElementSibling;
        const iconEyeClose = inputField.nextElementSibling.nextElementSibling;

        if (inputField.type === 'password') {
            inputField.type = 'text';
            iconEyeOpen.classList.add("input-visible");
            iconEyeClose.style.display = "none";
        } else {
            inputField.type = 'password';
            iconEyeOpen.classList.remove("input-visible");
            iconEyeClose.style.display = "block";
        }
    }

    // Add event listener to both input fields
    document.querySelectorAll('.js-input-toggle-visibility').forEach(input => {
        input.addEventListener('click', toggleInputVisibility);
    });


    document.addEventListener('statusUpdateEvent', (status) => {
        if (status.detail) {
            if (!status.detail.error) {
                // Data needs to be defined because a lot of copy and pasting moving from 1 file to another, less likely chance of failure (refactor later)
                let data = status.detail;

                // Store our FTP data so we can reveal at any point
                ftpUser = data.MyServerStatus.FtpUser;
                ftpPass = data.MyServerStatus.FtpPass;

                if (ftpUser.length <= 0 || ftpPass.length <= 0) {
                    ftpUsername.innerHTML = "Not defined";
                    ftpPassword.innerHTML = "Not defined";
                } else {
                    ftpUsername.value = ftpUser;
                    ftpPassword.value = ftpPass;
                }
            }
        }
    });
})();