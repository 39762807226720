'use strict';

import DataTransport from '@/helpers/data-transport';
import PingQuery from '@/helpers/ping-query';
import AlertMessage from "@/helpers/alert-message";
import ServerStatus from "@/sections/gameservers/helper/server-status";

(function() {
    let packData = null;
    try {
        packData = packObject;
    } catch (e) {
        console.error("packObject is not defined");
        return;
    }

    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const serverStatus = new ServerStatus(packData.gameServerId, packData.showLog);
    const loader = '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>';
    const serverFeatures = document.getElementById('serverFeatures');
    const pingQuery = new PingQuery();
    const statusIpPort = document.getElementById('status_ipport');
    const statusStatus = document.getElementById('status_status');
    const statusPlayers = document.getElementById('status_players');
    const statusFirewall = document.getElementById('status_firewall');
    const statusPID = document.getElementById('status_pid');
    const statusPort = document.getElementById('status_ports');
    const statusLastCheck = document.getElementById('status_lastcheck');
    const autoBoot = document.getElementById('autoBoot');
    const autoUpdate = document.getElementById('autoUpdate');
    const updateOnBoot = document.getElementById('updateOnBoot');
    const friendlyName = document.querySelector('[data-role="friendly-name"]');
    const friendlyNameBtn = document.querySelector('[data-role="update-friendly-name"]');

    statusIpPort.value = "Loading...";
    statusIpPort.disabled = true;
    statusStatus.innerHTML = loader;
    statusPlayers.innerHTML = loader;

    if (packData.showAll) {
        statusFirewall.innerHTML = loader;
        statusPID.innerHTML = loader;
        statusPort.innerHTML = loader;
        statusLastCheck.innerHTML = loader;
    }

    document.addEventListener('statusUpdateEvent', (status) => {
        if (status.detail) {
            if (status.detail.error) {
                //document.getElementById('status_servername').innerHTML = "Unable to contact server";
                statusIpPort.innerHTML = "Unable to contact server";
                statusStatus.innerHTML = "Unable to contact server";
                statusPlayers.innerHTML = "Unable to contact server";
                if (packData.showAll) {
                    statusFirewall.innerHTML = "Unable to contact server";
                    statusPID.innerHTML = "Unable to contact server";
                    statusPort.innerHTML = "Unable to contact server";
                    statusLastCheck.innerHTML = "Unable to contact server";
                }
                serverFeatures.classList.add("d-none");
                friendlyName.disabled = true;
                friendlyNameBtn.disabled = true;
            } else {
                // Data needs to be defined because a lot of copy and pasting moving from 1 file to another, less likely chance of failure (refactor later)
                let data = status.detail;

                //document.getElementById('status_servername').innerHTML = data.MyServerStatus.ServerName;
                const serverStatusIcon = data.MyServerStatus.Status == 'Running' ? 'server-status-icon --on' : 'server-status-icon --off';

                statusIpPort.disabled = false;
                statusIpPort.value = data.MyServerStatus.IPPort;
                statusStatus.innerHTML = '<span class="' + serverStatusIcon + '"></span>' + data.MyServerStatus.Status;
                statusPlayers.innerHTML = data.MyServerStatus.Players;

                if (packData.showAll) {
                    statusFirewall.innerHTML = data.MyServerStatus.FirewallRuleStatus;
                    statusPID.innerHTML = data.MyServerStatus.ProcessId;
                    statusPort.innerHTML = data.MyServerStatus.Ports;
                    statusLastCheck.innerHTML = data.MyServerStatus.LastStatusCheck;
                }

                // Request PingQuery
                const [ip, port] = data.MyServerStatus.IPPort.split(':');
                // This is just for admin debugging so we can quickly access pingquery data
                if (document.getElementById('pingQueryDebugLink')) {
                    if (packData.gameDigName) {
                        document.getElementById('pingQueryDebugLink').innerHTML = '<a href="' + window.location.origin + ':2053/query?type=' + packData.gameDigName + '&host=' + ip + '&port=' + data.MyServerStatus.Ports + '" class="btn btn-outline mt-3 w-100" target="_blank">Debug link</a>';
                    }
                }

                // Setup and run pingquery for the client
                if (!data.MyServerStatus.Online) {
                    pingQuery.StopQuery();
                    PingQueryError("Start your server to start PingQuery.");
                } else {
                    try {
                        // We can safely set params on every single loop, it makes no difference
                        pingQuery.SetParams(packData.gameDigName, ip, data.MyServerStatus.Ports, 15000);

                        // We can also safely tell it to query on every single loop, as pingQuery will just ignore everything once its started
                        pingQuery.StartQuery();
                    } catch (e) {
                        PingQueryError(e.message);
                    }
                }

                // Server features will only update on first load - removing the if statement would make them update on each update, but we could get conflicts with users enabling/disabling them at the eaxct moment updates come in.
                if (serverFeatures.classList.contains("d-none")) {
                    serverFeatures.classList.remove('d-none');
                    autoBoot.checked = data.MyServerStatus.AutoBoot;
                    autoUpdate.checked = data.MyServerStatus.AutoUpdate;
                    updateOnBoot.checked = data.MyServerStatus.UpdateOnBoot;
                }
                // On first load, undisable the friendly name and allow it to be edited
                if (friendlyName.disabled) {
                    friendlyName.disabled = false;
                    friendlyNameBtn.disabled = false;
                    friendlyName.value = data.MyServerStatus.FriendlyName;
                }
            }
        }
    })

    // Feature updates
    autoBoot.addEventListener('click', () => {
        UpdateFeatures();
    })
    autoUpdate.addEventListener('click', () => {
        UpdateFeatures();
    })
    updateOnBoot.addEventListener('click', () => {
        UpdateFeatures();
    })

    function UpdateFeatures() {
        const ab = autoBoot.checked ? 1 : 0;
        const au = autoUpdate.checked ? 1 : 0;
        const uob = updateOnBoot.checked ? 1 : 0;

        transport.GetRawResponse(`/EditServer?exec=updateFeatures&gameServerId=${packData.gameServerId}`, { 'autoBoot' : ab, 'autoUpdate' : au, 'updateOnBoot': uob })
            .then(data => {
                console.log(data);
                alertMessage.SetActiveAlert("server-features");
                alertMessage.ShowSuccess("Your server features were updated successfully!");
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    }

    if (friendlyNameBtn) {
        friendlyNameBtn.addEventListener('click', async function() {
            const btnContents = friendlyNameBtn.innerHTML;
            friendlyNameBtn.disabled = true;
            friendlyNameBtn.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"></div>';


            transport.GetRawResponse(`/EditServer?exec=updateFriendlyName&gameServerId=${packData.gameServerId}`, { 'friendlyName' : friendlyName.value })
                .then(async data => {
                    await new Promise(r => setTimeout(r, 1500));
                    friendlyNameBtn.disabled = false;
                    friendlyNameBtn.innerHTML = btnContents;
                    alertMessage.SetActiveAlert("friendly-name");
                    alertMessage.ShowSuccess("Your server friendly name was updated successfully!");
                })
                .catch(error => console.error('Failed to fetch data:', error))
                .finally(() => {

                });
        });
    }

    // PingQuery Related functions
    pingQuery.addEventListener('query', (event) => {
        console.log(event.detail);

        if (event.detail.error) {
            PingQueryError(event.detail.error);
        } else {
            PingQuerySetContent(event.detail.name, event.detail.numplayers, event.detail.maxplayers, event.detail.version)
        }
    });
    function PingQueryError(error) {
        PingQuerySetContent("", "", "", "", error);
    }
    function PingQuerySetContent(hostname, numplayers, maxplayers, version, error) {
        const pingQueryContent = document.getElementById('pingQuery');
        const pingQueryStats = pingQueryContent.querySelector('.js-pingquery-stats');
        const statusContent = pingQueryContent.querySelector(".status");
        const hostnameContent = pingQueryContent.querySelector(".hostname");
        const playersContent = pingQueryContent.querySelector(".players");
        const versionContent = pingQueryContent.querySelector(".version");

        if (error) {
            statusContent.innerHTML = "<div><small>" + error + "</small></div>";
            pingQueryStats.classList.add("d-none");
        } else {
            statusContent.innerHTML = "";
            pingQueryStats.classList.remove("d-none");
            hostnameContent.innerHTML = hostname;
            playersContent.innerHTML = numplayers + " / " + maxplayers;
            versionContent.innerHTML = version;
        }
    }
})();