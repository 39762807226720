'use strict';

module.exports = class AlertMessage {
    constructor() {
        this.alert = document.querySelectorAll('[data-role="alert-message"]'); // Alert to show errors.
        if (this.alert.length === 0) console.warn("alert-message does not exist.");
    }

    SetActiveAlert(dataId) {
        if (document.querySelector('[data-role="alert-message"][data-id="'+dataId+'"]')) {
            this.alert = document.querySelectorAll('[data-role="alert-message"][data-id="'+dataId+'"]');
        }
    }

    RemoveClasses() {
        this.alert.forEach((e) => {
            e.classList.remove('d-none', 'alert-success', 'alert-danger', 'alert-warning');
        });
    }

    HideAlert() {
        if (this.alert.length === 0) return;
        this.alert.forEach((e) => {
            e.classList.add("d-none");
        });
    }

    ShowError(msg) {
        if (this.alert.length === 0) return;
        this.RemoveClasses();
        this.alert.forEach((e) => {
            e.innerHTML = msg;
            e.classList.add('alert-danger');
        });
    }

    ShowSuccess(msg) {
        if (this.alert.length === 0) return;
        this.RemoveClasses();
        this.alert.forEach((e) => {
            e.innerHTML = msg;
            e.classList.add('alert-success');
        });
    }

    ShowWarning(msg) {
        if (this.alert.length === 0) return;
        this.RemoveClasses();
        this.alert.forEach((e) => {
            e.innerHTML = msg;
            e.classList.add('alert-warning');
        });
    }
}
