'use strict';

import DataTransport from '@/helpers/data-transport';

(async function() {
    const transport = new DataTransport();

    const game= document.querySelector('[data-role="game"]');
    const articleSelection = document.querySelector('[data-role="article-selection"]');
    const articleTitle = document.querySelector('[data-role="article-title"]');
    const articleSystemPrompt = document.querySelector('[data-role="article-system-prompt"]');
    const articlePrompt = document.querySelector('[data-role="article-prompt"]');
    const articlePredefinedId = document.querySelector('[data-role="article-predefined-id"]');
    let predefinedArticles;

    // Get the predefined templates from the DB.
    transport.GetResponse(`/admincp/Knowledgebase/?exec=predefinedTemplates`)
        .then(async data => {
            if (typeof data === 'object') {
                console.log(data);

                predefinedArticles = data;

                data.forEach((d) => {
                    addArticle(d.title, d.title);
                })
            } else {
                // Raw Text;
            }
        })
        .catch(error => console.error('Failed to fetch data:', error))
        .finally(() => {

        });

    game.addEventListener('change', (e) => {
        LoadPredefinedTemplate();
    });
    articleSelection.addEventListener('change', (e) => {
        LoadPredefinedTemplate();
    });

    function LoadPredefinedTemplate() {
        if (articleSelection.selectedOptions[0].value == 0) {
            articleTitle.value = "";
            articleSystemPrompt.value = "";
            articlePrompt.value = "";
            articlePredefinedId.value = 0;
            return;
        }
        predefinedArticles.forEach((a) => {
            if (a.title == articleSelection.selectedOptions[0].value) {
                articleTitle.value = ProcessString(a.title);
                articleSystemPrompt.value = ProcessString(a.systemPrompt);
                articlePrompt.value = ProcessString(a.prompt);
                articlePredefinedId.value = a.predefinedId;
            }
        })
    }

    function ProcessString(value) {
        return value.replace("%GAMENAME%", game.selectedOptions[0].innerHTML);
    }

    function addArticle(text, value) {
        // Create a new option element
        const option = document.createElement('option');

        // Set the text and value for the option
        option.textContent = text;
        option.value = value;

        // Append the option to the select element
        articleSelection.appendChild(option);
    }
})();