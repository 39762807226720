'use strict';

import DataTransport from '@/helpers/data-transport';
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();
    const userSearchForm = document.querySelector('[data-role="user-search-form"]');

    userSearchForm.addEventListener('submit', async function(e) {
        e.preventDefault();

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        await transport.PostFormData('/admincp/UserList/?exec=search', formData)
            .then(async data => {
                if (typeof data === 'object') {
                    //console.log(data);
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                    } else {
                        // success
                        window.location = `/admincp/UserProfile/?userId=${data.data.userId}&tab=`;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();