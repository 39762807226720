'use strict';

import LoadingPage from "@/helpers/loading-page";
import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const loadingPage = new LoadingPage();
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    const updateArticleForm = document.querySelector('[data-role="update-article-form"]');
    const url = new URL(window.location);

    updateArticleForm.addEventListener("submit", async function(e) {
        e.preventDefault();

        // Get the submit button
        const submitButton = this.querySelector('button[type="submit"]');
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        /*
        // For debugging or customization purposes, log the form data as key-value pairs
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        return;
         */

        submitButton.innerHTML = "Please Wait";
        submitButton.disabled = true;

        await transport.PostFormData('/admincp/Knowledgebase/?exec=updatePage&id=' + parseInt(url.searchParams.get('id')), formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        submitButton.disabled = false;
                        submitButton.innerHTML = submitButtonTxt;
                    } else {
                        alertMessage.ShowSuccess(data.message);
                        submitButton.disabled = false;
                        submitButton.innerHTML = submitButtonTxt;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();